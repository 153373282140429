import * as React from "react";
import { Helmet } from "react-helmet";
import { withPrefix, Link } from "gatsby";

import VideoBg from "../components/videoBg";

import { FRONT_DOMAIN } from "../config";

import "../scss/main.scss";
import 'swiper/css';
import PageHeader from "../components/pageHeader";
import PageSubHeader   from "../components/pageSubHeader";
import CaseTestimonials from "../components/caseTestimonials";
import CaseSwitcher from "../components/caseSwitcher";
import CaseHeroImage from "../components/caseHeroImage";
import PrismImage0 from "../img/cases/prism/image_0.png";
import PrismImage from "../img/cases/prism/preview.png";
import PrismImage1 from "../img/cases/prism/image_1.png";
import CaseImagesBlock from "../components/caseImagesBlock";
import PrismImage21 from "../img/cases/prism/image_2_1.png";
import PrismImage22 from "../img/cases/prism/image_2_2.png";
import PrismImage23 from "../img/cases/prism/image_2_3.png";
import PrismImage3 from "../img/cases/prism/image_3.png";
import CaseImageSlide from "../components/caseImageSlide";
import PrismImage41 from "../img/cases/prism/image_4_1.png";
import PrismImage42 from "../img/cases/prism/image_4_2.png";
import PrismImage43 from "../img/cases/prism/image_4_3.png";
const PrismCase = () => {

    return (
        <>
            <Helmet>
                <title>Prism: The Art of Light | Works | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap" rel="stylesheet"/>
            </Helmet>

            <VideoBg/>

            <div className="site-section__about-top-background"></div>

            <section id="main-section" className="site-section">
                <PageHeader
                    titleText={"Prism: The Art of Light"}
                    subTitleText={"An AI-powered journey bringing human emotions into spatial colourful spheres"}
                />
                <CaseTestimonials testimonials={['Artificial intelligence', 'Spatial design', '3D cinematic']}/>
                <CaseHeroImage image={PrismImage0} imageALT="Prism: The Art of Light"/>
                <PageSubHeader text="The PRISM pop-up experience was designed to showcase the exceptional capabilities of the Xiaomi 14, co-engineered with Leica.THE PRISM, is a modern reinterpretation of the Camera Obscura, is designed to capture the light and transform it into something extraordinary."/>
                <CaseHeroImage image={PrismImage1} imageALT="Prism: The Art of Light"/>
                <PageSubHeader text="An AI character the LightShaper, who is the embodiment of the Xiaomi 14's creative capabilities, guides you through THE PRISM while the Xiaomi 14 Pro becomes the tool and companion which allows you to interact with, personalize, and capture the installation. The person who is interacting with LightShaper, brings the artwork to life, making each experience uniquely their own."/>
                <CaseImagesBlock images={[PrismImage21, PrismImage22, PrismImage23]}/>
                <CaseImageSlide
                    image={PrismImage3}
                    title="Business objective:"
                    text="Xiaomi always strives to invent cutting-edge visual experiences. In this project, they wanted to introduce their most sophisticated AI-driven smartphone at that time, the Xiaomi 14. However, they didn't want to &quot;tell&quot; the audience and urge them to agree. Instead, they wanted to &quot;show&quot; how Xiaomi 14 can visualise any wild imagination with a simple touch and voice sensory."/>
                <PageSubHeader text="THE PRISM not only demonstrates the Xiaomi 14 Pro's exceptional low-light performance and high dynamic range but also serves as a guide to exploring its creative capabilities. Here, the Xiaomi 14 Pro emerges not just as a phone but as a medium for capturing and creating collective art."/>
                <CaseImagesBlock images={[PrismImage41, PrismImage42, PrismImage43]}/>
                <CaseSwitcher previousLink="/works/digital-muse/" previousName="Digital Muse" nextLink="/works/bentley/" nextName="Bentley digital box"/>
            </section>

        </>
    )
}

export default PrismCase;
